import { Skeleton, Table } from '@mui/material'
import { curveMonotoneX } from '@visx/curve'
import { Group } from '@visx/group'
import { TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import {
  ChartError,
  ChartGraph,
  ChartHeader,
  ChartLayout,
  ChartLegend,
  ChartLegendItem,
  ChartLoading,
  ChartMainValue,
  ChartNoData,
  ChartTitle,
  ChartValue,
  ChartValueComparison,
  CircleMark,
  formatChartTimestamp,
  LineWithShadow,
  OrangeMarker,
  PurpleMarker,
  ResponsiveVisXChart,
  TooltipContainer,
  TooltipContent,
  TooltipHeader,
  useTimelineIndexer,
} from 'features/chart'
import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum } from 'helpers/format'
import { useCurrentUser } from 'hooks/api/users'
import { SubscriptionStatistic, SubscriptionStatisticValue, UsageCounterPeriod } from 'models'

import { useInsightsStats } from '../../../WorkspaceInsights/useInsightsStats'
import { useFailedApiCallsComparison, Value } from '../hooks/useFailedApiCallsComparison'
import { useFailedApiCallsTooltipData } from '../hooks/useFailedApiCallsTooltipData'
import { InsightsChartProps } from '../InsightsChartProps'

const timestampAccessor = (v: Value) => v.timestamp.valueOf()

export function FailedApiCallsContent({ action, granularity, showTable }: InsightsChartProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const { isLoading, error, values, xDomain, yDomain } = useFailedApiCallsComparison(
    subscriptionId,
    currentUser,
    dateRange,
    granularity
  )

  const { handlePointer, tooltipIndex, position } = useTimelineIndexer(values, timestampAccessor)
  const tooltipData = useFailedApiCallsTooltipData(tooltipIndex, values, dateRange)

  return (
    <ChartLayout>
      <Header action={action} />
      {showTable ? (
        <ChartTable isLoading={isLoading} values={values} granularity={granularity} />
      ) : (
        <>
          <Legend />
          <ChartGraph>
            {isLoading ? <ChartLoading /> : null}
            {error ? <ChartError /> : null}
            {!isLoading && !error && values != null && values?.length === 0 ? <ChartNoData /> : null}
            {isLoading || error || values?.length === 0 ? null : (
              <ResponsiveVisXChart
                timezone={currentUser?.timezone}
                xDomain={xDomain}
                yDomain={yDomain}
                margin={{ top: 0, right: 0, bottom: 20, left: 20 }}
                svgClassName='overflow-visible'
                handlePointer={handlePointer}
              >
                {({ xScale, yScale }) => (
                  <>
                    {/* To make sure that the lines don't go under the axis, apply a cliping path around the Lines. */}
                    <Group clipPath='url(#content)'>
                      <LineWithShadow
                        data={values}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.restricted ?? 0)}
                        stroke='hsl(var(--fpds-color-purple-7))'
                        strokeWidth={1.5}
                        strokeOpacity={1}
                      />

                      <LineWithShadow
                        data={values}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.throttled ?? 0)}
                        stroke='hsl(var(--fpds-color-orange-7))'
                        strokeWidth={1.5}
                        strokeOpacity={1}
                      />
                    </Group>
                    {tooltipData != null ? (
                      <>
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.restricted ?? 0)}
                          fill='hsl(var(--fpds-color-purple-7))'
                        />
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.throttled ?? 0)}
                          fill='hsl(var(--fpds-color-orange-7))'
                        />
                      </>
                    ) : null}
                  </>
                )}
              </ResponsiveVisXChart>
            )}
            {tooltipData != null ? (
              <TooltipContainer position={position}>
                <TooltipHeader>
                  <span className='font-mono'>{formatChartTimestamp(tooltipData.periodStart, granularity)}</span>
                </TooltipHeader>
                <TooltipContent className='grid grid-cols-[auto_1fr_auto] gap-x-3 gap-y-2 items-center justify-between '>
                  <OrangeMarker />
                  <span className='text-gray-800'>Throttled calls</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.throttled != null ? formatNum(tooltipData.throttled) : 0}
                  </span>

                  <PurpleMarker />
                  <span className='text-gray-800'>Restricted calls</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.restricted != null ? formatNum(tooltipData.restricted) : 0}
                  </span>
                </TooltipContent>
              </TooltipContainer>
            ) : null}
          </ChartGraph>
        </>
      )}
    </ChartLayout>
  )
}

function Header({ action }: Pick<InsightsChartProps, 'action'>) {
  const { data: stats, isLoading: statsLoading } = useInsightsStats()
  const throttled = stats?.find((s) => s.type === SubscriptionStatistic.ThrottledApiCalls)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.ThrottledApiCalls]
    | undefined
  const restricted = stats?.find((s) => s.type === SubscriptionStatistic.RestrictedApiCalls)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.RestrictedApiCalls]
    | undefined

  const currentThrottled = throttled ? parseInt(throttled.currentPeriod) : null
  const currentRestricted = restricted ? parseInt(restricted.currentPeriod) : null
  const currentValue =
    currentThrottled == null || currentRestricted == null ? null : currentThrottled + currentRestricted

  const previousThrottled = throttled ? parseInt(throttled.prevPeriod) : null
  const previousRestricted = restricted ? parseInt(restricted.prevPeriod) : null
  const previousValue =
    previousThrottled == null || previousRestricted == null ? null : previousThrottled + previousRestricted

  return (
    <ChartHeader>
      <ChartTitle
        title='Failed API calls'
        info='Shows the number of throttled and restricted API calls for the selected period.'
        action={action}
      />
      <ChartValue>
        <ChartMainValue>
          {currentValue != null ? formatNum(currentValue) : <Skeleton width={80} height={28} />}
        </ChartMainValue>
        <ChartValueComparison
          isLoading={statsLoading}
          value={currentValue ?? undefined}
          previousValue={previousValue ?? undefined}
        />
      </ChartValue>
    </ChartHeader>
  )
}

function Legend() {
  return (
    <ChartLegend>
      <ChartLegendItem>
        <OrangeMarker />
        Throttled calls
      </ChartLegendItem>
      <ChartLegendItem>
        <PurpleMarker />
        Restricted Calls
      </ChartLegendItem>
    </ChartLegend>
  )
}

function ChartTable({
  isLoading,
  values,
  granularity,
}: {
  isLoading?: boolean
  values: Value[]
  granularity?: UsageCounterPeriod
}) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='30%'>Time</TableCell>
          <TableCell width='35%' align='right'>
            Throttled calls
          </TableCell>
          <TableCell width='35%' align='right'>
            Restricted calls
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody columnCount={3} isLoading={isLoading}>
        <TableBodyData>
          {values.map((v) => (
            <TableRow key={v.timestamp.valueOf()}>
              <TableCell>{formatChartTimestamp(v.timestamp, granularity)}</TableCell>
              <TableCell align='right'>{v.throttled != null ? formatNum(v.throttled) : null}</TableCell>
              <TableCell align='right'>{v.restricted != null ? formatNum(v.restricted) : null}</TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
