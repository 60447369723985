import { DateTime } from 'luxon'

import { SupportedSignalName } from './SignalName'
import { SmartSignalMetric } from './SmartSignalMetric'

export const supportedSignalsPresentationalInfo: Record<
  SupportedSignalName,
  Pick<SmartSignalMetric, 'platform' | 'tooltip' | 'label'> & { availabilityBoundary: DateTime }
> = {
  totalGoodBots: {
    label: 'Good bots',
    tooltip: 'Well-known web crawler or other search engine bot.',
    platform: ['js'],
    availabilityBoundary: DateTime.fromISO('2023-03-15T00:00:00Z'),
  },
  totalBadBots: {
    label: 'Bad bots',
    tooltip: 'Automated tools that don’t have legitimate uses and assumes fraudulent activity.',
    platform: ['js'],
    availabilityBoundary: DateTime.fromISO('2022-09-08T00:00:00Z'),
  },
  totalIncognito: {
    label: 'Incognito mode',
    tooltip: 'Detected incognito mode in major browsers.',
    platform: ['js'],
    availabilityBoundary: DateTime.fromISO('2023-04-25T00:00:00Z'),
  },
  totalTampering: {
    label: 'Browser tampering',
    tooltip:
      'Detected User-Agent spoofing or changes to the output of selected signals that are collected from the browser.',
    platform: ['js'],
    availabilityBoundary: DateTime.fromISO('2023-04-25T00:00:00Z'),
  },
  totalTamperingAndroid: {
    label: 'Rooted device',
    tooltip: 'Detected that the Android device is rooted.',
    platform: ['android'],
    availabilityBoundary: DateTime.fromISO('2023-05-10T00:00:00Z'),
  },
  totalVpn: {
    label: 'VPN',
    tooltip: 'Detected that the device uses an active VPN connection.',
    platform: ['js', 'android', 'ios'],
    availabilityBoundary: DateTime.fromISO('2023-04-25T00:00:00Z'),
  },
  totalIpBlocklist: {
    label: 'IP blocklist',
    tooltip: 'Detected that the IP address is listed in different public and proprietary blocklists.',
    platform: ['js', 'android', 'ios'],
    availabilityBoundary: DateTime.fromISO('2023-04-25T00:00:00Z'),
  },
  totalVirtualMachines: {
    label: 'VM detection',
    tooltip: 'Detected the browser is running inside a virtualization software like VirtualBox.',
    platform: ['js'],
    availabilityBoundary: DateTime.fromISO('2023-11-01T00:00:00Z'),
  },
  totalPrivacySettings: {
    label: 'Privacy settings',
    tooltip: 'Detected that privacy settings are turned on in privacy-focused browsers like Firefox or Brave.',
    platform: ['js'],
    availabilityBoundary: DateTime.fromISO('2023-06-27T00:00:00Z'),
  },
  totalHighActivity: {
    label: 'High-activity device',
    tooltip: 'Detected spikes in traffic connected to a single visitor ID.',
    platform: ['js', 'android', 'ios'],
    availabilityBoundary: DateTime.fromISO('2023-11-13T00:00:00Z'),
  },
  totalEmulator: {
    label: 'Android emulator',
    tooltip: 'Detected that the device is an Android emulator software.',
    platform: ['android'],
    availabilityBoundary: DateTime.fromISO('2023-05-11T00:00:00Z'),
  },
  totalAppCloners: {
    label: 'Cloned app',
    tooltip: 'Detected that request is coming from a cloned app.',
    platform: ['android'],
    availabilityBoundary: DateTime.fromISO('2023-06-27T00:00:00Z'),
  },
  totalFrida: {
    label: 'Frida',
    tooltip: 'Detected that Frida was used to dynamically instrument the app and change its behavior.',
    platform: ['android', 'ios'],
    availabilityBoundary: DateTime.fromISO('2023-10-29T00:00:00Z'),
  },
  totalJailbreak: {
    label: 'Jailbreak',
    tooltip:
      'Detected that device was jailbroken and that unauthorized changes were made to its underlying operating system.',
    platform: ['ios'],
    availabilityBoundary: DateTime.fromISO('2023-07-31T00:00:00Z'),
  },
}
