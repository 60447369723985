import { calculateTimelineTicks, mergeKeyedArrays, shiftTime } from 'features/chart'
import { SmartSignalMetric, SupportedSignalName } from 'features/smartSignals'
import { useCurrentSubscription } from 'features/subscription'
import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { useTimeline } from 'hooks/api/useTimeline'
import { UsageCounterPeriod } from 'models'
import { useEffect, useMemo, useState } from 'react'

import { sumSmartSignals } from './sumSmartSignals'

const defaultValues = {
  totalAppCloners: 0,
  totalBadBots: 0,
  totalEmulator: 0,
  totalFrida: 0,
  totalGoodBots: 0,
  totalHighActivity: 0,
  totalIncognito: 0,
  totalIpBlocklist: 0,
  totalJailbreak: 0,
  totalPrivacySettings: 0,
  totalSuccessfulIdentifications: 0,
  totalTampering: 0,
  totalTamperingAndroid: 0,
  totalVirtualMachines: 0,
  totalVpn: 0,
}

export function useSmartSignalsComparison(
  displayedMetrics: SmartSignalMetric[],
  dateRange?: CustomDateRange | PredefinedRange,
  granularity?: UsageCounterPeriod
) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const {
    data: current,
    isLoading,
    error,
  } = useTimeline(subscriptionId, ['smartSignals'], dateRange?.startDate, dateRange?.endDate, granularity)

  const { data: previous } = useTimeline(
    subscriptionId,
    ['smartSignals'],
    dateRange?.compareStart,
    dateRange?.compareEnd,
    granularity
  )

  const emptyCurrentValues = useMemo(
    () =>
      calculateTimelineTicks(dateRange?.startDate, dateRange?.endDate, granularity).map((timestamp) => ({
        timestamp,
        originalTimestamp: null,
        ...defaultValues,
      })),
    [dateRange, granularity]
  )
  const emptyPastValues = useMemo(
    () =>
      calculateTimelineTicks(dateRange?.compareStart, dateRange?.compareEnd, granularity).map((timestamp) => ({
        timestamp,
        originalTimestamp: null,
        ...defaultValues,
      })),
    [dateRange, granularity]
  )

  const currentValues = useMemo(
    () =>
      mergeKeyedArrays(emptyCurrentValues, sumSmartSignals(current?.smartSignals), 'timestamp', (ts) => ts.valueOf()),
    [current?.smartSignals, emptyCurrentValues]
  )

  const previousValues = useMemo(
    () =>
      mergeKeyedArrays(
        shiftTime(emptyPastValues, dateRange),
        shiftTime(sumSmartSignals(previous?.smartSignals), dateRange),
        'timestamp',
        (ts) => ts.valueOf()
      ),
    [dateRange, emptyPastValues, previous?.smartSignals]
  )

  const [displayedSignal, setDisplayedSignal] = useState<SupportedSignalName>('totalVpn')
  useEffect(() => {
    const shouldChangeDisplayed =
      displayedSignal == null || displayedMetrics.find((m) => m.signalName === displayedSignal) === undefined
    if (shouldChangeDisplayed && currentValues != null) {
      const toDisplay = displayedMetrics.find(
        (m) => currentValues[0]?.[m.signalName] != null && m.signalName !== 'totalSuccessfulIdentifications'
      )
      if (toDisplay && toDisplay.signalName !== 'totalSuccessfulIdentifications') {
        setDisplayedSignal(toDisplay.signalName)
      }
    }
  }, [displayedSignal, currentValues, displayedMetrics])

  const [xDomain, yDomain] = useMemo(() => {
    const timestamps = currentValues.map((p) => p.timestamp.valueOf() as number)
    const everyValue = displayedSignal
      ? [...currentValues.map((p) => p[displayedSignal] ?? 0), ...previousValues.map((p) => p[displayedSignal] ?? 0)]
      : []

    return [
      [Math.min(...timestamps), Math.max(...timestamps)],
      [0, Math.max(...everyValue, 10)],
    ] satisfies Array<[number, number]>
  }, [currentValues, displayedSignal, previousValues])

  return { currentValues, previousValues, isLoading, error, xDomain, yDomain, displayedSignal, setDisplayedSignal }
}
