import { Button } from '@compass/components'
import { InputLabel, Select, TextField, Typography } from '@mui/material'
import clsx from 'clsx'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { isValidIpAddress, isValidOrigin } from 'helpers/validation'
import {
  TrafficRule,
  TrafficRuleCreatePayload,
  TrafficRulePermissionType,
  TrafficRuleType,
  WorkspaceEnvironment,
} from 'models'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { USE_WORKSPACE_ENVIRONMENT } from '../../../../const'
import styles from './AllowListDialog.module.scss'

enum BehaviourType {
  AllowAll = 'allow_all',
  ForbidAll = 'forbid_all',
}

type TrafficFormRowData = {
  behaviour: BehaviourType
  entries: string
  workspaceEnvironmentId: string
}

interface TextAndLabelVariants {
  heading: string
  textFieldPlaceholder: string
  description: string
  itemName: string
  forbidAllEmptyInputError: string
}

const labelsByTrafficRule: Record<
  TrafficRuleType.Origin | TrafficRuleType.AppPackageName | TrafficRuleType.Network,
  TextAndLabelVariants
> = {
  [TrafficRuleType.Origin]: {
    heading: 'Configure rules',
    textFieldPlaceholder: 'https://domain.com or \nhttps://*.domain.com or \nhttps://23.43.136.* \none per line',
    description: 'Filter unauthorized identification requests by origin domain name.',
    itemName: 'websites',
    forbidAllEmptyInputError: 'Please specify at least one origin',
  },
  [TrafficRuleType.AppPackageName]: {
    heading: 'Configure rules',
    textFieldPlaceholder: 'com.example \none per line',
    description: 'Filter unauthorized identification requests by mobile app package name.',
    itemName: 'ones',
    forbidAllEmptyInputError: 'Please specify at least one package',
  },
  [TrafficRuleType.Network]: {
    heading: 'Configure rules',
    textFieldPlaceholder: '192.168.0.1/16 or \n127.0.0.1',
    description: 'Filter unauthorized identification requests by IP address.',
    itemName: 'IP addresses',
    forbidAllEmptyInputError: 'Please specify at least one IP address',
  },
}

export interface AllowListDialogProps extends DialogProps {
  trafficRules: TrafficRule[]
  environment: WorkspaceEnvironment
  onSubmit: (data: TrafficRuleCreatePayload[]) => void
  trafficRuleType: TrafficRuleType.Origin | TrafficRuleType.AppPackageName | TrafficRuleType.Network
}

export function AllowListDialog({
  trafficRules,
  environment,
  onSubmit,
  onClose,
  trafficRuleType,
  ...dialogProps
}: AllowListDialogProps) {
  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { errors },
  } = useForm()

  const [behaviourDefaultValue, setBehaviourDefaultValue] = useState<BehaviourType>()
  const [defaultExceptionEntries, setDefaultExceptionEntries] = useState<string>()

  useEffect(() => {
    reset()

    const relevantRules = trafficRules.filter(
      (rule) => rule.type === trafficRuleType && rule.workspaceEnvironmentId === environment?.id
    )
    const asteriskRule = relevantRules.find((rule) => rule.value === '*')

    setBehaviourDefaultValue(
      asteriskRule && asteriskRule.permissionType === TrafficRulePermissionType.Deny
        ? BehaviourType.ForbidAll
        : BehaviourType.AllowAll
    )

    setDefaultExceptionEntries(
      relevantRules
        .filter((rule) => rule.id !== asteriskRule?.id && rule.workspaceEnvironmentId === environment?.id)
        .map((rule) => rule.value)
        .join('\n')
    )
  }, [environment, reset, trafficRuleType, trafficRules])

  const labels = labelsByTrafficRule[trafficRuleType]

  function validateForm(data: TrafficFormRowData) {
    const entries = data.entries.split('\n').filter((entry) => entry.length > 0)
    const hasInvalidOrigins =
      trafficRuleType === TrafficRuleType.Origin && entries.some((entry) => !isValidOrigin(entry, true))
    const hasInvalidIPAddresses =
      trafficRuleType === TrafficRuleType.Network && entries.some((entry) => !isValidIpAddress(entry))

    if (entries.length === 0 && data.behaviour === BehaviourType.ForbidAll) {
      setError('entries', {
        type: 'validate',
        message: labels.forbidAllEmptyInputError,
      })
    } else if (hasInvalidOrigins) {
      setError('entries', {
        type: 'validate',
        message: 'Origin should match format: https://domain.com',
      })
    } else if (hasInvalidIPAddresses) {
      setError('entries', {
        type: 'validate',
        message: 'IP addresses should match format: 192.168.0.1/16 or 127.0.0.1',
      })

      // preprocess data
    } else {
      const rulesPayload: TrafficRuleCreatePayload[] = trafficRules
        .filter((rule) => rule.type === trafficRuleType && rule.workspaceEnvironmentId !== environment?.id)
        .map((rule) => {
          return {
            type: rule.type,
            value: rule.value,
            permissionType: rule.permissionType,
            workspaceEnvironmentId: rule.workspaceEnvironmentId,
          }
        })

      const type = trafficRuleType
      let permissionType: TrafficRulePermissionType
      const workspaceEnvironmentId = data.workspaceEnvironmentId

      if (data.behaviour === BehaviourType.AllowAll) {
        // TODO: move this logic to mgmt-api if still needed
        if (trafficRuleType !== TrafficRuleType.Network) {
          rulesPayload.push({
            type,
            permissionType: TrafficRulePermissionType.Allow,
            value: '*',
            workspaceEnvironmentId,
          })
        }

        permissionType = TrafficRulePermissionType.Deny
      } else {
        // TODO: move this logic to mgmt-api if still needed
        if (trafficRuleType !== TrafficRuleType.Network) {
          rulesPayload.push({
            type,
            permissionType: TrafficRulePermissionType.Deny,
            value: '*',
            workspaceEnvironmentId,
          })
        }

        permissionType = TrafficRulePermissionType.Allow
      }

      for (const value of entries) {
        rulesPayload.push({ type, value, permissionType, workspaceEnvironmentId })
      }

      onSubmit(rulesPayload)
    }
  }

  const handleClose = () => {
    reset()

    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog onClose={handleClose} {...dialogProps}>
      <form onSubmit={handleSubmit(validateForm)} noValidate>
        <DialogTitle onClose={handleClose}>{labels.heading}</DialogTitle>

        <DialogContent>
          <Typography className={styles.field}>{labels.description}</Typography>

          <InputLabel htmlFor='workspaceEnvironmentId'>Environment</InputLabel>
          {USE_WORKSPACE_ENVIRONMENT && (
            <Select
              id='workspaceEnvironmentId'
              native
              defaultValue={environment.id}
              readOnly
              required
              labelId='environment-label-id'
              inputProps={{
                'aria-label': 'Environment',
              }}
              {...muiRegister(register, 'workspaceEnvironmentId', {
                required: true,
              })}
              className={`${styles.field} ${styles.disabled}`}
            >
              <option key={environment.id} value={environment.id}>
                {environment.name}
              </option>
            </Select>
          )}

          <InputLabel htmlFor='defaultBehavior'>Default behaviour</InputLabel>
          <Select
            id='defaultBehavior'
            native
            defaultValue={behaviourDefaultValue}
            required
            labelId='default-behaviour-label-id'
            inputProps={{
              'aria-label': 'Default behaviour',
            }}
            {...muiRegister(register, 'behaviour', { required: true })}
            className={styles.field}
          >
            <option value={BehaviourType.AllowAll}>Allow all except the {labels.itemName} listed below</option>
            <option value={BehaviourType.ForbidAll}>Forbid all except the {labels.itemName} listed below</option>
          </Select>

          <InputLabel htmlFor='exceptions'>Exceptions</InputLabel>
          <TextField
            id='exceptions'
            inputProps={{ className: styles.exceptionsList, 'aria-label': 'Exceptions' }}
            placeholder={labels.textFieldPlaceholder}
            multiline
            fullWidth
            variant='outlined'
            defaultValue={defaultExceptionEntries}
            {...muiRegister(register, 'entries')}
            {...getErrorParams('entries', errors)}
          />
          {trafficRuleType === TrafficRuleType.Origin && (
            <Typography variant='body2' className={clsx(styles.caption, styles.row)}>
              Origins can contain wildcards (https://*.domain.com)
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button type='submit' aria-label='Save'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
