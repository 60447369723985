import { Grid, Skeleton, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import { DataCard } from 'features/commonUI'
import { SmartSignalMetric } from 'features/smartSignals'
import { CustomDateRange } from 'helpers/dateRange'
import { ampli } from 'models/ampli'

import { CtaCard } from '../../../commonUI/components/CtaCard/CtaCard'
import { DiffType } from '../../utils/models'
import { PlatformFilterIndicator } from '../PlatformFilterIndicator/PlatformFilterIndicator'
import { SmartSignalCard } from '../SmartSignalMetricCard/SmartSignalMetricCard'

interface SmartSignalsMetricsContentProps {
  displayedMetricData?: SmartSignalMetric[]
  totalApiCalls: bigint | null
  diffType: DiffType
  allSmartSignalsEnabled: boolean
  compareDateRange?: CustomDateRange
}

export function SmartSignalsMetricsContent({
  displayedMetricData,
  totalApiCalls,
  diffType,
  allSmartSignalsEnabled,
  compareDateRange,
}: SmartSignalsMetricsContentProps) {
  return (
    <Grid container spacing={2}>
      {displayedMetricData ? (
        displayedMetricData.map((metricData) => (
          <Grid item xs={12} sm={6} md={4} key={`${metricData.label}`}>
            <SmartSignalCard
              compareDateRange={compareDateRange}
              key={metricData.label}
              smartSignalMetricData={metricData}
              diffType={diffType}
              totalApiCalls={totalApiCalls}
            />
          </Grid>
        ))
      ) : (
        <LoadingContent />
      )}
      {!allSmartSignalsEnabled && (
        <Grid item xs={12} sm={6} md={4} key={`Contact Sales`}>
          <CtaCard
            title='Need more Smart Signals?'
            subtitle='Unlock device intelligence across every platform.'
            ctaTitle='Contact Sales'
            ctaPath={AppRoute.ContactSalesEnterprise}
            ctaOnClick={() => {
              ampli.appCtaClicked({ 'App Page Path': AppRoute.SmartSignals, ctaName: 'Contact sales' })
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

function LoadingCard() {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <DataCard subtitle={<PlatformFilterIndicator platforms={[]} hideNonSelected={false} />}>
        <div>
          <Skeleton width={80} height={28} />
          <Typography variant='bodyS' color='gray'>
            <Skeleton width={32} height={24} />
          </Typography>
        </div>
      </DataCard>
    </Grid>
  )
}

function LoadingContent() {
  return (
    <>
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </>
  )
}
