import { DateTime, Interval } from 'luxon'
import { UsageCounterPeriod } from 'models'

export function calculateTimelineTicks(startDate?: DateTime, endDate?: DateTime, granularity?: UsageCounterPeriod) {
  return startDate == null || endDate == null || granularity == null
    ? []
    : Interval.fromDateTimes(startDate.startOf(granularity), endDate)
        .splitBy({
          hour: granularity === UsageCounterPeriod.Hour ? 1 : undefined,
          day: granularity === UsageCounterPeriod.Day ? 1 : undefined,
          month: granularity === UsageCounterPeriod.Month ? 1 : undefined,
        })
        .map((segment) => segment.start!)
}
