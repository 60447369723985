import { ExtendedSignalName, signalNames } from 'features/smartSignals'
import { groupBy } from 'lodash'
import { DateTime } from 'luxon'

import { SmartSignalPoint } from './SmartSignalPoint'

export function sumSmartSignals(smartSignalPoints: SmartSignalPoint[] = []) {
  const platformValuesByTimestamp = Object.values(groupBy(smartSignalPoints ?? [], (m) => m.timestamp))
  const processed = platformValuesByTimestamp.map((platformValues) => {
    const summedValues = Object.fromEntries(
      signalNames.flatMap((key) => {
        if (key === 'totalTampering') {
          return [
            [
              'totalTampering',
              sumValuesOfKey(
                platformValues.filter((v) => v.platformType !== 'android'),
                'totalTampering'
              ),
            ],
            [
              'totalTamperingAndroid',
              sumValuesOfKey(
                platformValues.filter((v) => v.platformType === 'android'),
                'totalTampering'
              ),
            ],
          ]
        }

        return [[key, sumValuesOfKey(platformValues, key)]]
      })
    ) as Record<ExtendedSignalName, number>
    return {
      timestamp: DateTime.fromISO(platformValues[0]?.timestamp),
      ...summedValues,
    }
  })

  return processed
}

function sumValuesOfKey<V extends Record<string, string | null>, K extends keyof V>(values: V[], key: K) {
  return values.map((p) => parseInt(p[key] ?? '0')).reduce((acc, curr) => acc + curr, 0)
}
