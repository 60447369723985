import { SupportedSignalName } from 'features/smartSignals'
import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { useMemo } from 'react'

import { useSmartSignalsComparison } from './useSmartSignalsComparison'

export function useSmartSignalsTooltipData(
  tooltipIndexCurrent: number | undefined,
  tooltipIndexPrevious: number | undefined,
  currentValues: ReturnType<typeof useSmartSignalsComparison>['currentValues'],
  previousValues: ReturnType<typeof useSmartSignalsComparison>['previousValues'],
  dateRange: CustomDateRange | PredefinedRange | undefined,
  displayedSignal: SupportedSignalName
) {
  return useMemo(() => {
    if (tooltipIndexCurrent == null || tooltipIndexPrevious == null) {
      return null
    }
    return {
      currentPeriodStart: currentValues?.[tooltipIndexCurrent]?.timestamp ?? dateRange?.startDate,
      currentPeriodEnd: currentValues?.[tooltipIndexCurrent + 1]?.timestamp ?? dateRange?.endDate,
      currentValue: currentValues?.[tooltipIndexCurrent]?.[displayedSignal],
      previousPeriodStart: previousValues?.[tooltipIndexPrevious]?.originalTimestamp,
      previousPeriodEnd: previousValues?.[tooltipIndexPrevious + 1]?.originalTimestamp ?? dateRange?.compareEnd,
      previousValue: previousValues?.[tooltipIndexPrevious]?.[displayedSignal],
    }
  }, [
    currentValues,
    dateRange?.compareEnd,
    dateRange?.endDate,
    dateRange?.startDate,
    displayedSignal,
    previousValues,
    tooltipIndexCurrent,
    tooltipIndexPrevious,
  ])
}
