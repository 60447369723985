import { Stack } from '@compass/components'
import { Paper, Skeleton, Table, Typography } from '@mui/material'
import { Group } from '@visx/group'
import { Threshold } from '@visx/threshold'
import { TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import { curveMonotoneX } from 'd3-shape'
import {
  ChartError,
  ChartGraph,
  ChartHeader,
  ChartLayout,
  ChartLegend,
  ChartLegendItem,
  ChartLoading,
  ChartMainValue,
  ChartNoData,
  ChartTitle,
  ChartValue,
  ChartValueComparison,
  CircleMark,
  DisplaySelector,
  formatChartTimestamp,
  GranularitySelector,
  LineWithShadow,
  OrangeMarker,
  PurpleMarker,
  ResponsiveVisXChart,
  TooltipContainer,
  TooltipContent,
  TooltipHeader,
  useTimelineIndexer,
} from 'features/chart'
import { useDateRangeContext } from 'features/commonUI'
import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { formatNum } from 'helpers/format'
import { useCurrentUser } from 'hooks/api/users'
import { DateTime } from 'luxon'
import { UsageCounterPeriod } from 'models'
import { ReactNode, useCallback, useState } from 'react'

import { SupportedSignalName } from '../../utils/SignalName'
import { SmartSignalMetric } from '../../utils/SmartSignalMetric'
import { supportedSignalsPresentationalInfo } from '../../utils/supportedSignalsPresentationalInfo'
import { SmartSignalsChartSelector } from './SmartSignalsChartSelector'
import { sumSmartSignals } from './sumSmartSignals'
import { useSmartSignalsComparison } from './useSmartSignalsComparison'
import { useSmartSignalsTooltipData } from './useSmartSignalsTooltipData'

type Value = ReturnType<typeof sumSmartSignals>[number]
const timestampAccessor = (v: { timestamp: DateTime }) => v.timestamp.valueOf()

export function SmartSignalsChart({ displayedMetrics }: { displayedMetrics: SmartSignalMetric[] }) {
  const [granularity, setGranularity] = useState<UsageCounterPeriod>()
  const [display, setDisplay] = useState<'chart' | 'table'>('chart')

  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const { currentValues, previousValues, isLoading, error, xDomain, yDomain, displayedSignal, setDisplayedSignal } =
    useSmartSignalsComparison(displayedMetrics, dateRange, granularity)

  const {
    handlePointer: handlePointerCurrent,
    tooltipIndex: tooltipIndexCurrent,
    position,
  } = useTimelineIndexer(currentValues, timestampAccessor)
  const { handlePointer: handlePointerPrevious, tooltipIndex: tooltipIndexPrevious } = useTimelineIndexer(
    previousValues,
    timestampAccessor
  )

  const handlePointer = useCallback(
    (x0: number | null, tooltipLeft: number | null) => {
      handlePointerPrevious(x0, tooltipLeft)
      return handlePointerCurrent(x0, tooltipLeft)
    },
    [handlePointerCurrent, handlePointerPrevious]
  )

  const tooltipData = useSmartSignalsTooltipData(
    tooltipIndexCurrent,
    tooltipIndexPrevious,
    currentValues,
    previousValues,
    dateRange,
    displayedSignal
  )

  return (
    <Paper className='relative p-6'>
      <SmartSignalsChartSelector
        displayedMetrics={displayedMetrics}
        selectedChart={displayedSignal}
        onSelectionChange={setDisplayedSignal}
        className='absolute top-[18px] right-2'
      />
      <ChartLayout>
        <Header
          representation={
            displayedMetrics?.length > 0 ? supportedSignalsPresentationalInfo[displayedSignal] : undefined
          }
          values={displayedMetrics?.find((m) => m.signalName === displayedSignal)}
          action={
            <Stack gap={2} className='flex-grow flex-wrap items-center justify-start sm:justify-end mr-6 -my-1'>
              <GranularitySelector value={granularity} onChange={setGranularity} />
              <DisplaySelector value={display} onChange={setDisplay} />
            </Stack>
          }
          dateRange={dateRange}
        />
        {display === 'table' ? (
          <ChartTable
            isLoading={isLoading}
            values={currentValues}
            displayedSignal={displayedSignal}
            granularity={granularity}
          />
        ) : (
          <>
            <Legend />
            <ChartGraph>
              {isLoading ? <ChartLoading /> : null}
              {error ? <ChartError /> : null}
              {!isLoading &&
              !error &&
              ((displayedMetrics != null && displayedMetrics.length === 0) ||
                (currentValues != null && currentValues.length === 0)) ? (
                <ChartNoData />
              ) : null}
              {isLoading ||
              error ||
              displayedMetrics.length === 0 ||
              displayedSignal == null ||
              currentValues?.length === 0 ? null : (
                <ResponsiveVisXChart
                  timezone={currentUser?.timezone}
                  xDomain={xDomain}
                  yDomain={yDomain}
                  margin={{ top: 0, right: 0, bottom: 20, left: 20 }}
                  svgClassName='overflow-visible'
                  handlePointer={handlePointer}
                >
                  {({ xScale, yScale, yMax }) => (
                    <>
                      {/* To make sure that the lines don't go under the axis, apply a cliping path around the Lines. */}
                      <Group clipPath='url(#content)'>
                        {/* Previous values with purple area below. */}
                        <Threshold
                          id={`${Math.random()}`}
                          data={previousValues}
                          defined={(point) => {
                            const boundary = supportedSignalsPresentationalInfo[displayedSignal]?.availabilityBoundary
                            return (
                              boundary == null ||
                              (point.originalTimestamp != null && boundary < point.originalTimestamp)
                            )
                          }}
                          x={(point) => xScale(point.timestamp)}
                          y0={(point) => yScale(point[displayedSignal] ?? 0)}
                          y1={yMax}
                          clipAboveTo={(point) => yScale(point[displayedSignal] ?? 0)}
                          clipBelowTo={yMax}
                          curve={curveMonotoneX}
                          aboveAreaProps={{
                            fill: 'hsl(var(--fpds-color-purple-1))',
                            fillOpacity: 0.5,
                          }}
                        />
                        <LineWithShadow
                          data={previousValues}
                          curve={curveMonotoneX}
                          defined={(point) => {
                            const boundary = supportedSignalsPresentationalInfo[displayedSignal]?.availabilityBoundary
                            return (
                              boundary == null ||
                              (point.originalTimestamp != null && boundary < point.originalTimestamp)
                            )
                          }}
                          x={(point) => xScale(point.timestamp)}
                          y={(point) => yScale(point[displayedSignal] ?? 0)}
                          stroke='hsl(var(--fpds-color-purple-7))'
                          strokeWidth={1.75}
                          strokeOpacity={1}
                        />

                        {/* Current values with orange area below. */}
                        <Threshold
                          id={`${Math.random()}`}
                          data={currentValues}
                          x={(point) => xScale(point.timestamp)}
                          y0={(point) => yScale(point[displayedSignal] ?? 0)}
                          y1={yMax}
                          clipAboveTo={(point) => yScale(point[displayedSignal] ?? 0)}
                          clipBelowTo={yMax}
                          curve={curveMonotoneX}
                          aboveAreaProps={{
                            fill: 'hsl(var(--fpds-color-orange-1))',
                            fillOpacity: 0.5,
                          }}
                        />
                        <LineWithShadow
                          data={currentValues}
                          curve={curveMonotoneX}
                          x={(point) => xScale(point.timestamp)}
                          y={(point) => yScale(point[displayedSignal] ?? 0)}
                          stroke='hsl(var(--fpds-color-orange-7))'
                          strokeWidth={1.75}
                          strokeOpacity={1}
                        />
                      </Group>
                      {tooltipData != null ? (
                        <>
                          {tooltipData.previousPeriodStart == null ||
                          (supportedSignalsPresentationalInfo[displayedSignal]?.availabilityBoundary != null &&
                            tooltipData.previousPeriodStart <
                              supportedSignalsPresentationalInfo[displayedSignal]?.availabilityBoundary) ? null : (
                            <CircleMark
                              cx={tooltipData.currentPeriodStart ? xScale(tooltipData.currentPeriodStart) : undefined}
                              cy={yScale(tooltipData.previousValue)}
                              fill='hsl(var(--fpds-color-purple-7))'
                            />
                          )}
                          {tooltipData.currentValue == null ? null : (
                            <CircleMark
                              cx={tooltipData.currentPeriodStart ? xScale(tooltipData.currentPeriodStart) : undefined}
                              cy={yScale(tooltipData.currentValue)}
                              fill='hsl(var(--fpds-color-orange-7))'
                            />
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </ResponsiveVisXChart>
              )}
              {tooltipData != null ? (
                <TooltipContainer position={position}>
                  <TooltipHeader>Signal detected</TooltipHeader>
                  <TooltipContent className='grid grid-cols-[auto_1fr_auto] gap-x-2 gap-y-2 items-center justify-between'>
                    <OrangeMarker />
                    <span className='font-mono text-gray-800'>
                      {formatChartTimestamp(tooltipData.currentPeriodStart, granularity)}
                    </span>
                    <span className='ml-4 font-mono font-medium text-right'>
                      {tooltipData.currentValue != null ? formatNum(tooltipData.currentValue) : ''}
                    </span>

                    {tooltipData.previousPeriodStart == null ||
                    (supportedSignalsPresentationalInfo[displayedSignal]?.availabilityBoundary != null &&
                      tooltipData.previousPeriodStart <
                        supportedSignalsPresentationalInfo[displayedSignal]?.availabilityBoundary) ? null : (
                      <>
                        <span className='col-span-3 text-2xs text-gray-800'>Compared to:</span>

                        <PurpleMarker />
                        <span className='font-mono text-gray-800'>
                          {formatChartTimestamp(tooltipData.previousPeriodStart, granularity)}
                        </span>
                        <span className='ml-4 font-mono font-medium text-right'>
                          {tooltipData.previousValue != null ? formatNum(tooltipData.previousValue) : ''}
                        </span>
                      </>
                    )}
                  </TooltipContent>
                </TooltipContainer>
              ) : null}
            </ChartGraph>
          </>
        )}
      </ChartLayout>
    </Paper>
  )
}

function Legend() {
  return (
    <ChartLegend>
      <ChartLegendItem>
        <OrangeMarker />
        Current period
      </ChartLegendItem>
      <ChartLegendItem>
        <PurpleMarker />
        Previous period
      </ChartLegendItem>
    </ChartLegend>
  )
}

function Header({
  representation,
  values,
  action,
  dateRange,
}: {
  representation?: Pick<SmartSignalMetric, 'platform' | 'tooltip' | 'label'> & { availabilityBoundary: DateTime }
  values?: SmartSignalMetric
  action?: ReactNode
  dateRange?: CustomDateRange | PredefinedRange
}) {
  const showAvailabilityBoundary =
    representation?.availabilityBoundary != null &&
    dateRange?.compareStart != null &&
    representation?.availabilityBoundary &&
    dateRange?.compareStart < representation?.availabilityBoundary

  return (
    <ChartHeader>
      <ChartTitle title={representation?.label ?? ''} info={representation?.tooltip} action={action} />
      <ChartValue>
        <ChartMainValue>
          {values != null ? formatNum(values.value ?? 0) : <Skeleton width={80} height={28} />}
        </ChartMainValue>
        <ChartValueComparison
          isLoading={representation == null}
          value={values?.value ? Number(values.value) : undefined}
          previousValue={values?.previousPeriodValue ? Number(values?.previousPeriodValue) : undefined}
        />
        {showAvailabilityBoundary && (
          <Typography variant='bodyS'>
            Data for this Smart Signal starts from: {representation.availabilityBoundary.toFormat('yyyy-MM-dd')}
          </Typography>
        )}
      </ChartValue>
    </ChartHeader>
  )
}

function ChartTable({
  isLoading,
  values,
  displayedSignal,
  granularity,
}: {
  isLoading?: boolean
  values: Value[]
  displayedSignal: SupportedSignalName
  granularity?: UsageCounterPeriod
}) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='50%'>Time</TableCell>
          <TableCell width='50%' align='right'>
            Signal detected
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody columnCount={2} isLoading={isLoading}>
        <TableBodyData>
          {values.map((v) => (
            <TableRow key={v.timestamp.valueOf()}>
              <TableCell>{formatChartTimestamp(v.timestamp, granularity)}</TableCell>
              <TableCell align='right'>{formatNum(v[displayedSignal])}</TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
