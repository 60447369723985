import { CounterQueryType } from 'const'
import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { ValueOf } from 'helpers/types'
import { useUsageCounters } from 'hooks/api/usage_counters'
import { DateTime } from 'luxon'
import { UsageCounterPeriod, UsageCounterType, User } from 'models'
import { useMemo } from 'react'

export type Value = { timestamp: DateTime; value: number; originalTimestamp?: DateTime }

export function useApiCallsComparison(
  accessKey: ValueOf<typeof UsageCounterType>,
  subscriptionId?: string,
  currentUser?: User,
  dateRange?: CustomDateRange | PredefinedRange,
  granularity?: UsageCounterPeriod
) {
  const commonProps = {
    subscriptionId,
    // for usage counters handle, dates should be in user timezone
    from: dateRange?.startDate,
    to: dateRange?.endDate,
    queryType: CounterQueryType.IdentificationApiCalls,
    period: granularity,
    timezone: currentUser?.timezone,
    enabled: currentUser != null,
  } satisfies Partial<Parameters<typeof useUsageCounters>[0]>

  const {
    data: usageCountersData,
    isLoading: usageCountersLoading,
    error: usageCountersError,
  } = useUsageCounters({
    ...commonProps,
    from: dateRange?.startDate,
    to: dateRange?.endDate,
  })
  const {
    data: previousUsageCountersData,
    isLoading: previousUsageCountersLoading,
    error: previousUsageCountersError,
  } = useUsageCounters({
    ...commonProps,
    from: dateRange?.compareStart,
    to: dateRange?.compareEnd,
  })

  const values: Value[] = useMemo(
    () =>
      usageCountersData?.[accessKey]?.map(({ timestamp, value }) => ({
        timestamp: DateTime.fromISO(timestamp?.split(/[+Z]/).shift() + '', {
          zone: currentUser?.timezone ?? 'UTC',
        }),
        value,
      })) ?? [],
    [accessKey, currentUser?.timezone, usageCountersData]
  )
  const compareValues: Value[] = useMemo(
    () =>
      previousUsageCountersData?.[accessKey]?.slice(0, values.length).map(({ timestamp, value }, index) => ({
        timestamp: values[index]?.timestamp,
        originalTimestamp: DateTime.fromISO(timestamp?.split(/[+Z]/).shift() + '', {
          zone: currentUser?.timezone ?? 'UTC',
        }),
        value,
      })) ?? [],
    [accessKey, currentUser?.timezone, previousUsageCountersData, values]
  )

  const [xDomain, yDomain] = useMemo(() => {
    const points = [...(values ?? []), ...(compareValues ?? [])]
    const timestamps = points.map((p) => p.timestamp.valueOf() as number)
    const everyValue = points.map((p) => p.value)

    return [
      [Math.min(...timestamps), Math.max(...timestamps)],
      [0, Math.max(...everyValue, 10)],
    ] satisfies Array<[number, number]>
  }, [compareValues, values])

  return useMemo(
    () => ({
      isLoading: usageCountersLoading || previousUsageCountersLoading,
      error: usageCountersError || previousUsageCountersError,
      values,
      compareValues,
      xDomain,
      yDomain,
    }),
    [
      compareValues,
      previousUsageCountersError,
      previousUsageCountersLoading,
      usageCountersError,
      usageCountersLoading,
      values,
      xDomain,
      yDomain,
    ]
  )
}
