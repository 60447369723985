export function mergeKeyedArrays<
  K extends string,
  V,
  M,
  A extends {
    [k in K]: V
  },
  B extends {
    [k in K]: V
  },
>(arrayA: A[], arrayB: B[], key: K, keyMapper: (k: B[K]) => M) {
  const bMap = new Map(arrayB.map((item) => [keyMapper(item[key]), item]))
  return arrayA.map((item) => bMap.get(keyMapper(item[key] as any)) ?? item)
}
