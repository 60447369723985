import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { DateTime } from 'luxon'

export function shiftTime<V extends { timestamp: DateTime }>(
  values: V[],
  dateRange?: CustomDateRange | PredefinedRange
): Array<
  V & {
    timestamp: DateTime<boolean>
    originalTimestamp: DateTime<boolean>
  }
> {
  const difference = dateRange?.startDate.diff(dateRange?.compareStart, ['months', 'days', 'hours'])
  return values.map((p) => ({
    ...p,
    timestamp: difference ? p.timestamp.plus(difference) : p.timestamp,
    originalTimestamp: p.timestamp,
  }))
}
