import { Skeleton, Table } from '@mui/material'
import { curveMonotoneX } from '@visx/curve'
import { Group } from '@visx/group'
import { Threshold } from '@visx/threshold'
import { TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import {
  ChartError,
  ChartGraph,
  ChartHeader,
  ChartLayout,
  ChartLegend,
  ChartLegendItem,
  ChartLoading,
  ChartMainValue,
  ChartNoData,
  ChartTitle,
  ChartValue,
  ChartValueComparison,
  CircleMark,
  formatChartTimestamp,
  LineWithShadow,
  OrangeMarker,
  PurpleMarker,
  ResponsiveVisXChart,
  TooltipContainer,
  TooltipContent,
  TooltipHeader,
  useTimelineIndexer,
} from 'features/chart'
import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum } from 'helpers/format'
import { useCurrentUser } from 'hooks/api/users'
import { SubscriptionStatistic, SubscriptionStatisticValue, UsageCounterPeriod, UsageCounterType } from 'models'

import { useInsightsStats } from '../../../WorkspaceInsights/useInsightsStats'
import { useApiCallsComparison, Value } from '../hooks/useApiCallsComparison'
import { useApiCallsTooltipData } from '../hooks/useApiCallsTooltipData'
import { InsightsChartProps } from '../InsightsChartProps'

const timestampAccessor = (v: Value) => v.timestamp.valueOf()

export function ApiUsageContent({ action, granularity, showTable }: InsightsChartProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const { isLoading, error, values, compareValues, xDomain, yDomain } = useApiCallsComparison(
    UsageCounterType.ApiCalls,
    subscriptionId,
    currentUser,
    dateRange,
    granularity
  )

  const { handlePointer, tooltipIndex, position } = useTimelineIndexer(values, timestampAccessor)
  const tooltipData = useApiCallsTooltipData(tooltipIndex, values, dateRange, compareValues)

  return (
    <ChartLayout>
      <Header action={action} />
      {showTable ? (
        <ChartTable isLoading={isLoading} values={values} granularity={granularity} />
      ) : (
        <>
          <Legend />
          <ChartGraph>
            {isLoading ? <ChartLoading /> : null}
            {error ? <ChartError /> : null}
            {!isLoading && !error && compareValues != null && compareValues?.length === 0 ? <ChartNoData /> : null}
            {isLoading || error || compareValues?.length === 0 ? null : (
              <ResponsiveVisXChart
                timezone={currentUser?.timezone}
                xDomain={xDomain}
                yDomain={yDomain}
                margin={{ top: 0, right: 0, bottom: 20, left: 20 }}
                svgClassName='overflow-visible'
                handlePointer={handlePointer}
              >
                {({ xScale, yScale, yMax }) => (
                  <>
                    {/* To make sure that the lines don't go under the axis, apply a cliping path around the Lines. */}
                    <Group clipPath='url(#content)'>
                      {/* Previous values with purple area below. */}
                      <Threshold<Value>
                        id={`${Math.random()}`}
                        data={compareValues}
                        x={(point) => xScale(point.timestamp)}
                        y0={(point) => yScale(point.value)}
                        y1={yMax}
                        clipAboveTo={(point) => yScale(point.value)}
                        clipBelowTo={yMax}
                        curve={curveMonotoneX}
                        aboveAreaProps={{
                          fill: 'hsl(var(--fpds-color-purple-1))',
                          fillOpacity: 0.5,
                        }}
                      />
                      <LineWithShadow
                        data={compareValues}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.value)}
                        stroke='hsl(var(--fpds-color-purple-7))'
                        strokeWidth={1.75}
                        strokeOpacity={1}
                      />

                      {/* Current values with orange area below. */}
                      <Threshold<Value>
                        id={`${Math.random()}`}
                        data={values}
                        x={(point) => xScale(point.timestamp)}
                        y0={(point) => yScale(point.value)}
                        y1={yMax}
                        clipAboveTo={(point) => yScale(point.value)}
                        clipBelowTo={yMax}
                        curve={curveMonotoneX}
                        aboveAreaProps={{
                          fill: 'hsl(var(--fpds-color-orange-1))',
                          fillOpacity: 0.5,
                        }}
                      />
                      <LineWithShadow
                        data={values}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.value)}
                        stroke='hsl(var(--fpds-color-orange-7))'
                        strokeWidth={1.75}
                        strokeOpacity={1}
                      />
                    </Group>
                    {tooltipIndex != null && tooltipData != null ? (
                      <>
                        <CircleMark
                          cx={tooltipData.currentPeriodStart ? xScale(tooltipData.currentPeriodStart) : undefined}
                          cy={yScale(tooltipData.previousValue)}
                          fill='hsl(var(--fpds-color-purple-7))'
                        />
                        <CircleMark
                          cx={tooltipData.currentPeriodStart ? xScale(tooltipData.currentPeriodStart) : undefined}
                          cy={yScale(tooltipData.currentValue)}
                          fill='hsl(var(--fpds-color-orange-7))'
                        />
                      </>
                    ) : null}
                  </>
                )}
              </ResponsiveVisXChart>
            )}
            {tooltipIndex != null && tooltipData != null ? (
              <TooltipContainer position={position}>
                <TooltipHeader>API calls</TooltipHeader>
                <TooltipContent className='grid grid-cols-[auto_1fr_auto] gap-x-2 gap-y-2 items-center justify-between'>
                  <OrangeMarker />
                  <span className='font-mono text-gray-800'>
                    {formatChartTimestamp(tooltipData.currentPeriodStart, granularity)}
                  </span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.currentValue != null ? formatNum(tooltipData.currentValue) : ''}
                  </span>

                  <span className='col-span-3 text-2xs text-gray-800'>Compared to:</span>

                  <PurpleMarker />
                  <span className='font-mono text-gray-800'>
                    {formatChartTimestamp(tooltipData.previousPeriodStart, granularity)}
                  </span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.previousValue != null ? formatNum(tooltipData.previousValue) : ''}
                  </span>
                </TooltipContent>
              </TooltipContainer>
            ) : null}
          </ChartGraph>
        </>
      )}
    </ChartLayout>
  )
}
function Legend() {
  return (
    <ChartLegend>
      <ChartLegendItem>
        <OrangeMarker />
        Current period
      </ChartLegendItem>
      <ChartLegendItem>
        <PurpleMarker />
        Previous period
      </ChartLegendItem>
    </ChartLegend>
  )
}

function Header({ action }: Pick<InsightsChartProps, 'action'>) {
  const { data: stats, isLoading: statsLoading } = useInsightsStats()
  const usageTotal = stats?.find((s) => s.type === SubscriptionStatistic.Usage)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.Usage]
    | undefined

  return (
    <ChartHeader>
      <ChartTitle title='API usage' info='Shows API usage over time for the selected period.' action={action} />
      <ChartValue>
        <ChartMainValue>
          {usageTotal?.currentPeriod != null ? (
            formatNum(parseInt(usageTotal.currentPeriod))
          ) : (
            <Skeleton width={80} height={28} />
          )}
        </ChartMainValue>
        <ChartValueComparison
          isLoading={statsLoading}
          value={usageTotal?.currentPeriod ? parseInt(usageTotal?.currentPeriod) : undefined}
          previousValue={usageTotal?.prevPeriod ? parseInt(usageTotal?.prevPeriod) : undefined}
        />
      </ChartValue>
    </ChartHeader>
  )
}

function ChartTable({
  isLoading,
  values,
  granularity,
}: {
  isLoading?: boolean
  values: Value[]
  granularity?: UsageCounterPeriod
}) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='50%'>Time</TableCell>
          <TableCell width='50%' align='right'>
            API calls
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody columnCount={2} isLoading={isLoading}>
        <TableBodyData>
          {values.map((v) => (
            <TableRow key={v.timestamp.valueOf()}>
              <TableCell>{formatChartTimestamp(v.timestamp, granularity)}</TableCell>
              <TableCell align='right'>{formatNum(v.value)}</TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
