import { AxisBottom, AxisLeft } from '@visx/axis'
import { localPoint } from '@visx/event'
import { GridRows } from '@visx/grid'
import { Group } from '@visx/group'
import { withParentSize } from '@visx/responsive'
import { scaleLinear, scaleTime } from '@visx/scale'
import { Line } from '@visx/shape'
import { ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale'
import { formatNumShort } from 'helpers/format'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'

import { formatDateTime, zonedTicks } from '../3d-utils/zonedTicks'

export function VisXChart({
  parentWidth,
  parentHeight,
  margin = { top: 40, right: 30, bottom: 50, left: 40 },
  timezone = 'UTC',
  background = 'white',
  xDomain,
  yDomain,
  children,
  svgClassName,
  handlePointer,
}: {
  parentWidth: number
  parentHeight: number
} & {
  margin?: { top: number; right: number; bottom: number; left: number }
  timezone?: string
  background?: string
  xDomain: [number, number]
  yDomain: [number, number]
  children: ({
    xScale,
    yScale,
    xMax,
    yMax,
  }: {
    xScale: ScaleTime<number, number, never>
    yScale: ScaleLinear<number, number, never>
    xMax: number
    yMax: number
  }) => React.ReactNode
  svgClassName?: string
  handlePointer?: (x: number | null, tooltipLeft: number | null) => number | null
}) {
  const width = parentWidth
  const height = parentHeight // parentWidth * ASPECT_RATIO

  // bounds
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  // scales
  const xScale = useMemo(
    () =>
      scaleTime<number>({
        domain: xDomain,
        range: [0, xMax],
      }),
    [xDomain, xMax]
  )
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: yDomain,
        range: [yMax, 0],
        nice: true,
      }),
    [yDomain, yMax]
  )

  const [tooltipLeft, setTooltipLeft] = useState<number>()
  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGElement> | React.MouseEvent<SVGElement>) => {
      const { x } = localPoint(event) ?? { x: 0 }

      if (x < margin.left || x > parentWidth - margin.right) {
        setTooltipLeft(undefined)
        handlePointer?.(null, null)
        return
      }

      // setTooltipLeft(x)
      const x0 = xScale.invert(x - margin.left)
      const date = handlePointer?.(x0.valueOf(), x / parentWidth)
      setTooltipLeft(date ? xScale(date) + margin.left : undefined)
    },
    [margin.left, margin.right, parentWidth, xScale, handlePointer]
  )

  if (width < 10) return null

  const xNumTicks = parentWidth > 520 ? 10 : 5
  const start = DateTime.fromMillis(xDomain[0]).setZone(timezone)
  const stop = DateTime.fromMillis(xDomain[1]).setZone(timezone)
  const tickValues = zonedTicks(start, stop, xNumTicks)
  const slantedTicks = xMax / tickValues.length < 50

  return (
    <svg
      width={width}
      height={height}
      className={svgClassName}
      onTouchStart={handleTooltip}
      onTouchMove={handleTooltip}
      onMouseMove={handleTooltip}
      onMouseLeave={() => {
        setTooltipLeft(undefined)
        handlePointer?.(null, null)
      }}
    >
      <rect x={0} y={0} width={width} height={height} fill={background} />
      <Group left={margin.left} top={margin.top}>
        <GridRows
          numTicks={4}
          scale={yScale}
          width={xMax}
          height={yMax}
          stroke='hsl(var(--fpds-color-gray-3))'
          strokeDasharray='4 4'
        />
        <AxisBottom
          top={yMax}
          scale={xScale}
          tickValues={tickValues}
          tickFormat={(value: DateTime) => formatDateTime(value)}
          tickLabelProps={{
            fontFamily: 'JetBrains Mono',
            fontSize: '12px',
            fill: 'hsl(var(--fpds-color-gray-8))',
            textAnchor: slantedTicks ? 'middle' : undefined,
            angle: slantedTicks ? -20 : undefined,
          }}
          stroke='hsl(var(--fpds-color-gray-3))'
          tickStroke='transparent'
        />
        <AxisLeft
          scale={yScale}
          tickFormat={yDomain?.[1].valueOf() < 100 ? undefined : (v) => formatNumShort(v.valueOf())}
          numTicks={2}
          tickLabelProps={{
            fontFamily: 'JetBrains Mono',
            fontSize: '12px',
            fill: 'hsl(var(--fpds-color-gray-8))',
          }}
          stroke='transparent'
          tickStroke='transparent'
        />
        {tooltipLeft != null && (
          <g>
            <Line
              from={{ x: (tooltipLeft ?? 0) - margin.left, y: -margin.top }}
              to={{ x: (tooltipLeft ?? 0) - margin.left, y: yMax }}
              stroke='hsl(var(--fpds-color-gray-3))'
              strokeWidth={1}
              pointerEvents='none'
            />
          </g>
        )}
        {/* <GridColumns scale={xScale} width={xMax} height={yMax} stroke='hsl(var(--fpds-color-gray-3))' /> */}
        {/* <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke='hsl(var(--fpds-color-gray-3))' /> */}
        <ClipPath id='content' width={xMax} height={yMax} />
        {children?.({ xScale, yScale, xMax, yMax })}
      </Group>
    </svg>
  )
}

function ClipPath({ id, width, height }: { id: string; width: number; height: number }) {
  return (
    <defs>
      <clipPath id={id}>
        <rect width={width} height={height} />
      </clipPath>
    </defs>
  )
}

export const ResponsiveVisXChart = withParentSize(VisXChart)
