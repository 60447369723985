import { Badge, Button, SelectItem, SelectListBox, SelectPopover, Stack } from '@compass/components'
import clsx from 'clsx'
import { useUiPreferences } from 'hooks'
import { ChevronsUpDownIcon } from 'lucide-react'
import { useCallback, useState } from 'react'
import { Select } from 'react-aria-components'

import { SupportedSignalName } from '../../utils/SignalName'
import { SmartSignalMetric } from '../../utils/SmartSignalMetric'
import { supportedSignalsPresentationalInfo } from '../../utils/supportedSignalsPresentationalInfo'

export type SmartSignalChartSelectOption = {
  key: SupportedSignalName
  label: string
}

export function SmartSignalsChartSelector({
  displayedMetrics,
  selectedChart,
  onSelectionChange,
  className,
}: {
  displayedMetrics: SmartSignalMetric[]
  selectedChart?: SupportedSignalName
  onSelectionChange: (selected: SupportedSignalName) => void
  className?: string
}) {
  const { uiPreferences, updateUiPreferences } = useUiPreferences()
  const [isOpen, setIsOpen] = useState(false)

  const onOpenChange = useCallback(
    (open: boolean) => {
      updateUiPreferences({ isChartSelectorClicked: true })
      setIsOpen(open)
    },
    [updateUiPreferences]
  )

  return (
    <Stack gap={2} className={clsx('items-center', className)}>
      {uiPreferences.isChartSelectorClicked !== true ? (
        <Badge tone='default' variant='solid' className='motion-safe:animate-bounceXLeft mt-0.5'>
          New →
        </Badge>
      ) : null}
      <Select<SmartSignalChartSelectOption>
        aria-label='Displayed Smart Signal'
        selectedKey={selectedChart}
        onSelectionChange={onSelectionChange}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDisabled={displayedMetrics == null || displayedMetrics.length === 0}
      >
        <Button isIcon variant='ghost' aria-label='Change displayed Smart Signal' onPress={() => setIsOpen(true)}>
          <ChevronsUpDownIcon />
        </Button>
        <SelectPopover>
          <SelectListBox>
            {displayedMetrics?.map((metric) => {
              if (metric.signalName === 'totalSuccessfulIdentifications') {
                return null
              }

              const option = supportedSignalsPresentationalInfo[metric.signalName]
              if (option == null) {
                return null
              }

              return (
                <SelectItem
                  key={metric.signalName}
                  id={metric.signalName}
                  value={option}
                  isDisabled={metric.signalName === selectedChart}
                >
                  {option.label}
                </SelectItem>
              )
            })}
          </SelectListBox>
        </SelectPopover>
      </Select>
    </Stack>
  )
}
