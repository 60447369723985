export const signalNames = [
  'totalAppCloners',
  'totalBadBots',
  'totalEmulator',
  'totalFrida',
  'totalGoodBots',
  'totalHighActivity',
  'totalIncognito',
  'totalIpBlocklist',
  'totalJailbreak',
  'totalPrivacySettings',
  'totalSuccessfulIdentifications',
  'totalTampering',
  'totalVirtualMachines',
  'totalVpn',
] as const

export type SignalName = (typeof signalNames)[number]
export type ExtendedSignalName = SignalName | 'totalTamperingAndroid'
export type SupportedSignalName = Exclude<ExtendedSignalName, 'totalSuccessfulIdentifications'>
