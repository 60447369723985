import groupBy from 'lodash/groupBy'

import { SmartSignalMetric } from './SmartSignalMetric'

export function reduceMetrics(metricsForSelectedPlatforms: SmartSignalMetric[]): SmartSignalMetric[] {
  const metricsByLabel = groupBy(metricsForSelectedPlatforms, (m) => m.label)

  const metricsSummedByLabel = Object.keys(metricsByLabel).map((label) => {
    const metricsInDifferentPlatformCategories = metricsByLabel[label]
    let value: bigint | null = null
    let previousPeriodValue: bigint | null = null

    metricsInDifferentPlatformCategories.forEach((m) => {
      if (m.value !== null) {
        if (value === null) {
          value = m.value
        } else {
          value = value + m.value
        }
      }

      if (m.previousPeriodValue !== null) {
        if (previousPeriodValue === null) {
          previousPeriodValue = m.previousPeriodValue
        } else {
          previousPeriodValue = previousPeriodValue + m.previousPeriodValue
        }
      }
    })

    return {
      ...metricsInDifferentPlatformCategories[0],
      value,
      previousPeriodValue,
    }
  })

  const filteredMetrics = metricsSummedByLabel.filter((m) => m.value != null)
  const displayedMetrics = filteredMetrics.length > 0 ? filteredMetrics : metricsSummedByLabel

  return displayedMetrics
}
