import { Stack } from '@compass/components'
import { useCurrentSubscription } from 'features/subscription'
import { useTrafficRuleBulkUpdateMutation } from 'hooks'
import { TrafficRule, TrafficRuleCreatePayload, TrafficRuleType, WorkspaceEnvironment } from 'models'
import { useCallback } from 'react'

import { AllowList } from '../AllowList/AllowList'

interface NetworkSectionProps {
  trafficRules: TrafficRule[]
  isCreationLimitExceeded: boolean
  isLoading: boolean
  currentEnvironment: WorkspaceEnvironment
}

export function NetworkSection({
  trafficRules,
  isLoading,
  isCreationLimitExceeded,
  currentEnvironment,
}: NetworkSectionProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  const { mutate: sendTrafficRuleBulkUpdateRequest } = useTrafficRuleBulkUpdateMutation()

  const handleBulkUpdateTrafficRules = useCallback(
    (entities: TrafficRuleCreatePayload[]) => {
      const params = { subscriptionId }
      const data = { type: TrafficRuleType.Network, entities }
      sendTrafficRuleBulkUpdateRequest({ data, params })
    },
    [sendTrafficRuleBulkUpdateRequest, subscriptionId]
  )

  return (
    <Stack gap={8} direction='column'>
      <AllowList
        handleBulkUpdateRules={handleBulkUpdateTrafficRules}
        isLoading={isLoading}
        trafficRules={trafficRules}
        trafficRuleType={TrafficRuleType.Network}
        isCreationLimitExceeded={isCreationLimitExceeded}
        currentEnvironment={currentEnvironment}
      />
    </Stack>
  )
}
