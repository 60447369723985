import { Stack } from '@compass/components'
import { Skeleton, Typography } from '@mui/material'
import { formatPercent } from 'helpers/format'
import { TrendingDownIcon, TrendingUpIcon } from 'lucide-react'
import { PropsWithChildren } from 'react'

export function ChartValue({ children }: PropsWithChildren) {
  return (
    <Stack direction='column' gap={1}>
      {children}
    </Stack>
  )
}

export function ChartMainValue({ children }: PropsWithChildren) {
  return (
    <Typography variant='h1' component='p'>
      {children}
    </Typography>
  )
}

export function ChartValueComparison({
  isLoading,
  value,
  previousValue,
}: {
  isLoading?: boolean
  value?: number
  previousValue?: number
}) {
  if (isLoading === true) {
    return <Skeleton width={290} height={20} />
  }
  if (value == null || value === 0 || (value as any) === '0') {
    return null
  }

  if (previousValue == null) {
    return null
  }

  if (previousValue === 0 || (previousValue as any) === '0') {
    return (
      <Typography variant='bodyS'>
        <span className='text-orange-700'>
          <TrendingUpIcon size={16} className='inline-flex mr-1' />
          <span className='font-medium'>+100%</span>
        </span>
        &nbsp;compared to previous period
      </Typography>
    )
  }

  // Have data for both current and previous periods
  const difference = value - previousValue
  const percentage = difference / previousValue
  const shownPercentage = Number.isFinite(percentage) ? percentage : percentage > 0 ? 1 : -1

  const negative = shownPercentage < 0
  const tone = negative ? 'text-purple-700' : 'text-orange-700'

  return (
    <Typography variant='bodyS'>
      <span className={tone}>
        {negative ? (
          <TrendingDownIcon size={16} className='inline-flex mr-1' />
        ) : (
          <TrendingUpIcon size={16} className='inline-flex mr-1' />
        )}
        <span className='font-medium'>
          {negative ? '' : '+'}
          {formatPercent(shownPercentage)}
        </span>
      </span>
      &nbsp;compared to previous period
    </Typography>
  )
}
